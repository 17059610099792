import React, { useState, useEffect } from "react";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import SetContent from "../../utils/SetContent";
import { useHttp } from "../../hooks/http.hook";
import './_promocode.scss'
import trash from "./../../resourses/icons/trash.png";
const Promocode = () => {

    const [createCode, setCreateCode] = useState(false);
    const [codes, setCodes] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
        link: null
    });

    const { request, process, setProcess, test } = useHttp();


    //получаем список промокодв
    const getCodes = () => {
        if (test) {
            setCodes([
                { id: 1, name: 'tt52fg5', percent: '10', description: 'some text', title: 'a regular customer' },
                { id: 2, name: 'tt52cg1', percent: '10', description: 'some text', title: 'loyalty discount' },
                { id: 3, name: 'tt52fg2', percent: '10', description: 'some text', title: 'loyalty discount' }
            ])
            setProcess('confirmed');
        } else {
            request(undefined, {
                "command": "promo.read_promo"
            })
                .then(res => {
                    setProcess('confirmed');
                    setCodes(res.message)
                })
        }
    }

    useEffect(() => {
        getCodes();
    }, [])

    //обработчик инпутов
    const handlerInput = (id, target) => {
        const { name, value } = target;
        if(name === 'percent' && isNaN(value)) {
            return
        }
        
        setCodes(state => {
            const newState = structuredClone(state);
            return newState.map(item => {
                if (item.id === id) {
                    return { ...item, [name]: value, changed: true };
                  }
                  return {...item};
            })
        })
    }

    // отправляем запрос на сохранине промокода по id
    const onSave = (id) => {
        const savedCode = codes.find(item => item.id === id) // находим сохраняемый промокод
        
            request(undefined, {
                "command": "promo.add_promo",
                "id": savedCode.id,
                "name": savedCode.name,
                "percent": savedCode.percent,
                "description": savedCode.description,
                "title": savedCode.title,
            })
                .then(res => {
                    console.log(res);
                    setProcess('confirmed');
                    setCreateCode(false);
                    setCodes(res.message);
                })
    }

    // добавляем новоый промокод
    const addCode = () => {
        setCreateCode(true);
        setCodes(state => {
            const newState = structuredClone(state);
            return [...newState, {id: '', name: '', percent: '', description: '', title: ''}]
        })
    }

    // отправка запроса на удаление промокода
    const removeCode = (id) => {
        if(id === "") {
            setShowConfirm(false);
            setCreateCode(false);
            setCodes(state => {
                const newState = structuredClone(state);
                return newState.filter(item => item.id !== "")
            })
            return
        }
        request(undefined, {
            "command": "promo.remove_promo",
            "id": id
        })
            .then(res => {
                setProcess('confirmed');
                setShowConfirm(false);
                setCodes(res.message)
            })
    }

    const onConfirmDelete = (id, name, title) => {
        setShowConfirm(true);
        setTargetConfirm({id, name, title})
    }

    const codeList = codes.map(item => {
        const {id, name, percent, description, title, changed} = item;
        const disableBtn = name && percent && description && title && changed ? false : true;
        return (
            <li
                className="promocode__item"
                key={id}>
                <div className="promocode__box">
                    <div className="promocode__input">
                        <input
                            type="text"
                            name="name"
                            placeholder="name code"
                            maxLength={9}
                            value={name}
                            onChange={(e) => handlerInput(id, e.target)} />
                    </div>
                    <div className="promocode__input">
                        <input
                            type="text"
                            name="percent"
                            maxLength={2}
                            placeholder=""
                            value={percent}
                            onChange={(e) => handlerInput(id, e.target)}/>
                    </div>
                    <div className="promocode__input">
                        <input
                            type="text"
                            name="description"
                            placeholder="description"
                            value={description}
                            onChange={(e) => handlerInput(id, e.target)}/>
                    </div>
                    <div className="promocode__input">    
                        <input
                            type="text"
                            name="title"
                            placeholder="title"
                            value={title}
                            onChange={(e) => handlerInput(id, e.target)}/>
                    </div>
                    <button
                        disabled={disableBtn}
                        className="promocode__save button"
                        onClick={() => onSave(id)}>
                            Save
                    </button>
                </div>
                <button
                    className="promocode__del btn-trash"
                    onClick={() => onConfirmDelete(id, name, title)}>
                    <img src={trash} alt="delete" />
                </button>
            </li>
        )
    })

    const AddBtn = () => (
        <button className={`promocode__add button`} onClick={addCode}>
            Add promo code +
        </button>
    )
    
    return (
        <>
            <PanelHeader title="Promo codes" />
            <ul className="promocode">
                <SetContent process={process} component={codeList} />
            </ul>
            {createCode ? null : <AddBtn />}
            <ConfirmModal question='Delete the promo code?' text1={targetConfirm.name} text2={targetConfirm.title} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeCode(targetConfirm.id)}/>
        </>
    )
}

export default Promocode;