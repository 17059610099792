import React, {useState, useEffect, useRef} from "react";
import './_rate.scss';
import PanelHeader from './../../components/panelHeader/PanelHeader'
import happyTaco from './../../resourses/icons/happy-taco.png';
import Spinner from "../../components/Spinner/Spinner";
import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";


const Rate = () => {

	const [rates, setRates]  = useState([]);

	const {request, process, setProcess} = useHttp();

	const getRates = () => {

		request(undefined, {
        		"command": "rate.get_rate",
        	}).then(res => {
							setRates(res.message);
							setProcess('confirmed');
        	})         
	}


	useEffect( () => {
		getRates();
		// eslint-disable-next-line
	}, []);
	

	const renderItems = (rateArray) => {
		const cards = rateArray.map((card, i) => (
			<RateCard  key={card.id} {...card} getRates={getRates}/>
		))
		return (
		  <ul className="rate">
				{cards}
		  </ul>
		)
	  }
	
	return (
		<>
			<PanelHeader title="Prices"/>
			<SetContent process={process} component={renderItems(rates)}/>
		</>
	)
}

const RateCard = ({title, rate, id, getRates}) => {
	const [loading, setLoading] = useState(false);
	const [sended, setSended] = useState(false);
	
	let spinner;

    loading ? spinner = <Spinner active/> : spinner = <Spinner />

	const inputRef = useRef(0);
	const button = useRef(0);
	const [cost, setCost] = useState(rate);
	const [valid, setValid] = useState(true);
	const {request} = useHttp();

	useEffect(() => {
		button.current.setAttribute( "disabled", true );
	}, [])

	const onCheckDisable = (e) => {
		if(e.target.value.length >= 2) {
			setValid(true);
			button.current.removeAttribute( "disabled");
		} else {
			setValid(false);
			button.current.setAttribute( "disabled", true );
		}
	}

	
	const onSendCost = (cost) => {
		setLoading(true);

		request(undefined, {
			command: "rate.set_rate", 
			id: id,
			rate: cost
			
		})
        .then(res => {
			setLoading(false)
			setSended(true)
		})
	}

	let clazz;
	clazz = valid ? 'input' : 'input input--error'

	return (
		<>
			{spinner}
			<li className='card__item'>
				<h3 className="card__title">{title}</h3>
				<form>
					{sended ? (
						<>
							<img className="card__taco" src={happyTaco} alt="happy taco"/>
							<button 
								className="button" 
								type="button"
								onClick={() => {setSended(false); getRates()}}
								>Ok</button>
						</>
					) : (
						<>
							<label className="label">
								Cost, SAR
								<input 
								ref={inputRef}
								type="number" 
								name="cost" 
								className={clazz}
								placeholder={rate}
								defaultValue={rate}
								value={cost}
								onChange={(e) => {setCost(e.target.value); onCheckDisable(e)}}
								></input>
							</label>
							<button 
								ref={button}
								className="button" 
								type="button"
								onClick={() => onSendCost(cost)}
								>Save</button>
						</>
					)}
				</form>
			</li>
		</>
		
	)
}
export default Rate