import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import DishForm from '../../components/DishForm/DishForm';
import PanelHeader from '../../components/panelHeader/PanelHeader';
import SetContent from "../../utils/SetContent";
import { useHttp } from '../../hooks/http.hook';

function EditDish() {
  const {id} = useParams(); // тут будем получать id блюда, и по нему будем отправлять запрос

  const {request, process, setProcess, test} = useHttp();


  const [dish, setDish] = useState({
    name: '',
    arabian: '',
    type: '',
    kcal: '',
    carbs: '',
    fats: '',
    proteins: '',
    img1: '',
    img2: '',
    img3: '',
    allergens: [],
    days: []
  }); // параметры блюда, 



  //отправляем запрос получния данных о блюде
  useEffect(() => { 
    if (test) {
      
      setDish({
        name: 'Spaghetti Bolognese with meatbals',
        arabian: 'سباجيتي بولونيز مع كرات اللحم',
        type: ['0','0','1','0'],
        kcal: '630',
        carbs: '45',
        fats: '14',
        proteins: '16',
        img1: 'https://www.w-dog.ru/wallpapers/4/19/484842159283230/vtorye-blyuda-ryba-kartofel-limon-lajm-ovoshhi-tarelka-eda-foto.jpg',
        img2: 'https://www.w-dog.ru/wallpapers/4/19/484842159283230/vtorye-blyuda-ryba-kartofel-limon-lajm-ovoshhi-tarelka-eda-foto.jpg',
        img3: '',
        allergens: ['1','0','0','0','0','0','0','1','0','0'],
        days: ['1','0','0','0','1']
      })
      setProcess('confirmed');
    } else {
      request(undefined, {
        "command": "dish.get_dish",
        "id" : id
      }).then((res)=>{
        setDish({...res.message[0],
          ingredients: res.message[0].ingredients.join(),
          ingredientsAr: res.message[0].ingredientsAr.join(),
          ingredientsDelete: res.message[0].ingredientsDelete.join(),
          ingredientsDeleteAr: res.message[0].ingredientsDeleteAr.join(),
        })
        setProcess('confirmed');
      });
    }
   // eslint-disable-next-line
  }, []);

  return (
    <>
      <PanelHeader title="Meals"/>
      <SetContent
        process={process}
        component={<DishForm dish={dish} setDish={setDish}/>}
      />
      
    </>
  )
}

export default EditDish;