import React from 'react';
import './_spinner.scss'



const Spinner = ({active}) => {
    return (
        <div className={active ? 'spinner active' : 'spinner'}>
            <span className="loader"></span>
        </div>
    )
};

export default Spinner;