import React,  {useState, useEffect} from 'react';

import PanelHeader from "../../components/panelHeader/PanelHeader";
import SetContent from '../../utils/SetContent';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import { useHttp } from '../../hooks/http.hook';

const Support = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  const [support, setSupport] = useState({})

  const {request, process, setProcess, test} = useHttp();

  useEffect(() => {
    getSupport();
    
  }, [])

  // запрос на получение настроек
  const getSupport = () => {

      request(undefined, {
            command: 'settings.get_support_phone',
          })
          .then(res => {
            if (res.status === '1'){
              setProcess('confirmed')
              setSupport(res.message[0])
            } else {
              setTextAlert('Something went wrong');
              setShowAlert(true)
            }
          })
  }

// запрос на сохранение настроек
  const onSave = () => {
    console.log(support);

      request(undefined, {
            command: 'settings.update_support_phone',
            phone: support.support,
          })
          .then(res => {
            console.log(res);
            if (res.status === '1'){
              setProcess('confirmed');
              setTextAlert('The support number have been successfully saved');
            } else {
              setTextAlert('Something went wrong');
            }
            setShowAlert(true)
          })
  }

  const handlerInput = (target) => {
    const { name, value } = target;

    setSupport({...support,  [name]: value})
  }

  const checkForm = () => {
    if (support.support) {
      return false
    }
    return true
  }

  const renderSettings = (
    <div className='settings__inner'>
          <label className='settings__label'>
            <span>Telephone</span>
            <input
              className={`input ${support.support ? '' : 'input--error'}`}
              type='text'
              name='support'
              value={support.support}
              onChange={(e) => handlerInput(e.target)}/>
          </label>
          <button className='button' onClick={onSave} disabled={checkForm()}>Save</button>
        </div>
  )

  return (
    <>
      <PanelHeader title='Support'/>
      <div className='settings'>
        <SetContent process={process} component={renderSettings} />
      </div>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert}/>
    </>
  )
}

export default Support;