import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allergens: ['Seafoods', 'Eggs', 'Peanut Butter & Soy Beans', 'Celery', 'Mustard', 'Sesame seeds', 'Milk and lactose', 'Nuts', 'Grains and gluten free', 'Shellfish'],
  activities: ['Running', 'Soccer', 'Swimming', 'Dancing', 'Reading', 'Programming', 'Bicycle', 'Tennis', 'Box', 'Heavy gymnastics', 'Skateboard', 'Roller skates', 'Scooter', 'A boat', 'Basketball', 'Baseball', 'Skates'],
  typeDishes: ['Breakfast', 'Lunch', 'Snack', 'Dinner']
}

export const matrixSlice = createSlice({
  name: 'matrix',
  initialState,
  reducers: {
    setAllergens(state, action) {
      state.allergens = action.payload;
    },
    setActivities(state, action) {
      state.activities = action.payload;
  }
    }
})

export const { setAllergens, setActivities } = matrixSlice.actions;

export default matrixSlice.reducer;