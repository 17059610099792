import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    adminList: [],
    admin: '',
    currentAdmin: {
        login: '',
        name: ''
    }
      
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        addAdmins(state, action) {
            state.adminList = action.payload
        },
        editAdmin(state, action) {
            state.admin = action.payload
        },
        setCurrentAdmin(state, action){
          state.currentAdmin = action.payload;
        },
    },
})

export const { addAdmins, editAdmin, setCurrentAdmin } = adminSlice.actions;

export default adminSlice.reducer;