import React, {useEffect, useState, useRef} from "react";
import title from './../../resourses/icons/tummies-red.png';
import showPass from './../../resourses/icons/show-pass.png';
import secret from './../../resourses/icons/secret.png';
import happyTaco from './../../resourses/icons/happy-taco.png';
import { validate, res } from 'react-email-validator';
import Spinner from "../../components/Spinner/Spinner";
import { useHttp } from "../../hooks/http.hook";
import { Link, NavLink } from "react-router-dom";
import "./_recovery.scss";

const Recovery = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(true);
    const [sendEmailOnApi, setSendEmailOnApi] = useState(false);
    const [confirmCode, setConfirmCode] = useState(false)
    const [confirmError, setConfirmError] = useState(false)

    const {request, process, setProcess, test} = useHttp();


    let clazz;
    valid ? clazz = "input" : clazz = "input input--error";

    let spinner;

    loading ? spinner = <Spinner active/> : spinner = <Spinner />

    const button = useRef(0);

    useEffect(() => {
        if(button) {
            button.current.setAttribute( "disabled", true );
        }
    }, [])


    const onLogEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onLogCodeChange = (e) => {
        setCode(e.target.value);
    }

    const onCheckDisable = (e) => {
        validate(e.target.value)
        if (res) {
            button.current.removeAttribute( "disabled" );
            setValid(true);
        } else {
            button.current.setAttribute( "disabled", true );
            setValid(false)
        }
    }

    const onCheckDisableConfirm = (e) => {
        if (e.target.value.length === 5) {
            button.current.removeAttribute( "disabled" );
            setValid(true);
        } else {
            button.current.setAttribute( "disabled", true );
            setValid(false)
        }
    }

    const sendEmailAddress = (email) => {
        setLoading(true);
        if(test) {
            setLoading(true);
        // axios
        //     .post('./api/emailRecovery.php', {
        //         "email": email,
        //     })
        //     .then(res => {
        //     })
        setTimeout(() => {
            setLoading(false)
            setSendEmailOnApi(true)
        }, 300)
        } else {
            request(undefined, {
                command: '',
                "email": email
              })
              .then(res => {
                setProcess('confirm')
                setLoading(false)
                setSendEmailOnApi(true)
              })
        }
      
     
     
    }

    const sendCode = (code) => {
        setLoading(true);
        if (test) {
              // axios
        //     .post('./api/emailRecovery.php', {
        //         "code": code,
        //     })
        //     .then(res => {
            //      setConfirmCode(res.data.code)
            //      setCodeError(!res.data.code)

        //     })
        setTimeout(() => {
            setLoading(false)
            setConfirmCode(true)
            setConfirmError(false)
        }, 300)
        } else {
            request(undefined, {
                command: '',
                "code": code
              })
              .then(res => {
                setProcess('confirm')
                setLoading(false)
                setConfirmCode(true)
                setConfirmError(false)
              })
        }
      
     
       
    }


    let setContent;
    setContent = sendEmailOnApi ? ( confirmCode ? <NewPassword setConfirmCode={setConfirmCode}/> : <Confirmation clazz={clazz} code={code} onLogCodeChange={onLogCodeChange} setSendEmailOnApi={setSendEmailOnApi} onCheckDisableConfirm={onCheckDisableConfirm} button={button} sendCode={sendCode} confirmError={confirmError} email={email} sendEmailAddress={sendEmailAddress}  />) : <InputEmail clazz={clazz} email={email} onLogEmailChange={onLogEmailChange} onCheckDisable={onCheckDisable} button={button} sendEmailAddress={sendEmailAddress}/>

    return (
        <div className="recovery__container">
            <div className="recovery__wrapper">
                <div className="title">
                    <img src={title} alt="tummies"/>
                </div>
                <p className="subtitle">Password Recovery</p>
            </div>
            
            <form className="login__form">
                {spinner}
                {setContent}
            </form>
        
        </div>
    )
}



export default Recovery; 

const InputEmail = ({clazz, email, onLogEmailChange, onCheckDisable, button, sendEmailAddress}) => {
    return (
        <>
            <label className="label">
                    Email
                    <input 
                    type="email" 
                    name="login" 
                    className={clazz}
                    value={email}
                    onChange={(e) => {onLogEmailChange(e);
                        onCheckDisable(e)
                    }}
                    >

                    </input>
                </label>
                <p className="description">You will receive a confirmation code in your email</p>
                <button 
                    ref={button}
                    className="button" 
                    type="button"
                    onClick={() => sendEmailAddress(email)}
                    >Enter</button>
                <NavLink to="/" className="navlink" >
                    <button 
                    className="button" 
                    >Back</button>
                </NavLink>    
                    
        </>
    )
}

const Confirmation = ({clazz, code, onLogCodeChange, onCheckDisableConfirm, button, sendCode, confirmError, sendEmailAddress, email, setSendEmailOnApi}) => {

    const [ seconds, setSeconds ] = useState(60);
    const [ timerActive, setTimerActive ] = useState(true);

    const resent = useRef(0)
    useEffect(() => {
        if (seconds > 0 && timerActive) {
          setTimeout(setSeconds, 1000, seconds - 1);
          resent.current.setAttribute('disabled', true)
        } else {
          setTimerActive(false);
          resent.current.removeAttribute('disabled')
        }
      }, [ seconds, timerActive ]);


    let counterClazz;
    counterClazz = timerActive ? "recovery__counter" : "recovery__counter recovery__counter--opacity"

    let btnClazz;
    btnClazz = timerActive ? "button button--orange button--orange-opacity" : "button button--orange"


    
    return (
        <>
            <label className="label">
                Confirmation code
                <input 
                type="number" 
                name="login" 
                className={clazz}
                value={code}
                onChange={(e) => {onLogCodeChange(e)}}
                onInput={(e) => {onCheckDisableConfirm(e)}}
                >

                </input>
            </label>
            <button ref={resent} className={btnClazz} onClick={() => sendEmailAddress(email)}>Resend after</button>
            <div className={counterClazz}>{seconds}</div>
            {confirmError ?  <p className="error">Incorrect code</p> : null}
            <button 
                ref={button}
                className="button" 
                type="button"
                onClick={() => sendCode(code)}
                >Confirm
                </button>
            <div className="navlink" onClick={() => setSendEmailOnApi(false)}>
                <button 
                className="button" 
                >Back</button>
            </div>   
        </>
    )
}

const NewPassword = ({setConfirmCode}) => {
    const [typeInput, setTypeInput] = useState("password");
    const [typeInputRepeat, setTypeInputRepeat] = useState("password");

    const [newPass, setNewPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);

    const [isMatch, setIsMatch] = useState(true)
    const [valid, setValid] = useState(false);

    const [loading, setLoading] = useState(false)

    const [thanks, setThanks] = useState(false)

    const {request, process, setProcess, test} = useHttp();

    const onToggleVisibleNewPassword = () => {
        setShowNewPass(showNewPass => showNewPass = !showNewPass);
        if( typeInput === 'password') {
            setTypeInput('text')
        } else {
            setTypeInput("password")
        }
    }

    const onPassValidator = (e, trigger) => {
        if(e.target.value.length >= 7 && trigger.length >= 7) {
            setValid(true)
        } else {
            setValid(false)
        }
    }

    const onToggleVisibleRepeatPassword = () => {
        setShowRepeatPass(showRepeatPass => showRepeatPass = !showRepeatPass);
        if( typeInputRepeat === 'password') {
            setTypeInputRepeat('text')
        } else {
            setTypeInputRepeat("password")
        }
    }

    const sendNewPassword = (password) => {
        setLoading(true)
        if (test) {
                    // axios
            //     .post('./api/pass.php', {
            //         "new password": password,
            //     })
            //     .then(res => {
            //         setLoading(false)
            //         setThanks(true)
            //     })
            setTimeout(() => {
                setLoading(false)
                setThanks(true)
            }, 300)
        } else {
            request(undefined, {
                "command": "",
                "new password": password,
            })
        }
   
    }
    const button = useRef(0);
    let clazz;
    clazz = isMatch? 'input' : 'input input--error';
    const onCheckDisableSave = (e, trigger) => {
        onPassValidator(e, trigger);
        if((e.target.value === trigger) && valid) {
            button.current.removeAttribute('disabled')
            setIsMatch(true)
        } else {
            button.current.setAttribute('disabled', true)
            setIsMatch(false)
        }
    }

    let spinner;

    loading ? spinner = <Spinner active/> : spinner = <Spinner />
    
    if(!thanks) {
        return (
            <>
                {spinner}
                <label className="label">
                    New password
                    <input 
                        type={`${typeInput}`}
                        name="password" 
                        className={clazz}
                        value={newPass}
                        onChange={(e) => {setNewPass(e.target.value)}}
                        onInput={(e) => onCheckDisableSave(e, repeatPass)}
                        ></input>
                        <img onClick={() => onToggleVisibleNewPassword()} src={showNewPass ? showPass : secret}/>
                </label>
                <label className="label">
                    Repeat the new password
                    <input 
                        type={`${typeInputRepeat}`}
                        name="password" 
                        className={clazz}
                        value={repeatPass}
                        onChange={(e) => {setRepeatPass(e.target.value)}}
                        onInput={(e) => onCheckDisableSave(e, newPass)}
                        ></input>
                        <img onClick={() => onToggleVisibleRepeatPassword()} src={showRepeatPass ? showPass : secret}/>
                </label>
    
                <button 
                        ref={button}
                        className="button" 
                        type="button"
                        onClick={() => sendNewPassword(newPass)}
                        >Save</button>
                <div className="navlink" onClick={() => setConfirmCode(false)}>
                    <button 
                    className="button" 
                    >Back</button>
                </div>
            </>
        )
    } else {
        return (
            <>
                <p className="recovery__text">The Password has been successfully changed</p>
                <img className="recovery__taco" src={happyTaco} alt="happy taco"/>
                <button 
                        className="button recovery__login" 
                        type="button"
                        ><Link to="/">Login</Link></button>
                <div className="navlink" onClick={() => setThanks(false)}>
                    <button 
                    className="button" 
                    >Back</button>
                </div>
                
            </>
        )
    }
    
}