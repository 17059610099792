import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import SetContent from "../../utils/SetContent";
import Pagination from "../../components/Pagination/Pagination";
import { useHttp } from "../../hooks/http.hook";
import './_children.scss'
import trash from "./../../resourses/icons/trash.png";

const Children = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null);
    const [children, setChildren] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
    });

    const { request, process, setProcess, test } = useHttp();

    // get children list
    const getChildren = (pageNumber) => {
        if (test) {
            setProcess('confirmed');
            setChildren([
                { id: 1, name: 'Name', gender: 'male', email: 'email' },
                { id: 2, name: 'Name', gender: 'male', email: 'email' },
                { id: 3, name: 'Name', gender: 'male', email: 'email' }
            ])
        } else {
            request(undefined, {
                "command": "users.get_all_child_list",
                "page": `${pageNumber}`
            })
                .then(res => {
                    setProcess('confirmed');
                    setChildren(res.message[0].children);
                    setCurrentPage(res.message[0].current_page);
                    setTotalPages(res.message[0].max_page);
                })
        }
    }

    useEffect(() => {
        getChildren(currentPage);
        // eslint-disable-next-line
    }, [])

    const changePage = (step) => {
        console.log(step);
        // step  -1 means previous page and +1 is next
        getChildren(+currentPage + step);
        setCurrentPage(state => +state + step);
      }

    // отправляем запрос на удаление ребенка
    const removeChildren = (id) => {
        setShowConfirm(false);
        request(undefined, {
            "command": "users.remove_child",
            "id": `${id}`
        })
            .then(res => {
                if (res.status === '1') {
                    setShowAlert(true);
                    setTextAlert('The child has been successfully deleted');
                    getChildren(currentPage);
                    setProcess('confirmed');
                } else {
                    setProcess('error');
                }
            })
    }

    const onConfirmDelete = (id, name) => {
        setShowConfirm(true);
        setTargetConfirm({id, name})
    }

    const renderItems = (arr) => {
        const childrenList = arr.map(item => {
            const {id, name, gender, email} = item;
            return (
                <li key={id} className="rows-list__item">
                <div className='rows-list__box children__box'>
                    <span>{id}</span>
                    <span>{name}</span>
                    <span>{gender === '1' ? 'male' : 'female'}</span>
                    <span>{email}</span>
                   <Link className="rows-list__btn button" to={`/edit-child/${id}`}>Edit</Link>
                </div>
                <button 
                    className='btn-trash'
                    onClick={() => onConfirmDelete(id, name)}
                    >
                    <img src={trash} alt="delete"/>
                </button>
            </li>
            )
        });
        return (
            <ul className="rows-list">
                {childrenList}
            </ul>
        )
    }
    return (
        <>
            <PanelHeader title="Children" />
            <SetContent process={process} component={renderItems(children)}/>
            <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />

            <ConfirmModal question='Delete a child?' text1={targetConfirm.name} text2={null} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeChildren(targetConfirm.id)}/>
            <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
        </>
    )
}

export default Children;