import React, {useState, useEffect, useRef} from "react";
import './_catering.scss';
import PanelHeader from './../../components/panelHeader/PanelHeader';
import happyTaco from './../../resourses/icons/happy-taco.png';
import { useMask } from '@react-input/mask';
import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";


const Catering = () => {

	const [numbers, setNumbers]  = useState([]);

	const {request, process, setProcess} = useHttp();

	const getPhoneNumbers = () => {
		request(undefined, {
        		"command": "phone.get_number",
        	}).then(res => {
				setNumbers(res.message);
				setProcess('confirmed');
        	})         
	}


	useEffect( () => {
			getPhoneNumbers();
			// eslint-disable-next-line
	  }, []);


	const renderItems = (cardArray) => {
		const cards = cardArray.map((card) => (
			<CateringCard key={card.id} {...card} getPhoneNumbers={getPhoneNumbers}/>
		))
		return (
		  <ul className="catering">
			{cards}
		  </ul>
		)
	  }
	
	return (
		<>
			<PanelHeader title="Catering"/>
			<SetContent process={process} component={renderItems(numbers)}/>
		</>
	)
}
const CateringCard = ({title, number, id, getPhoneNumbers}) => {

	const [sended, setSended] = useState(false);
	const inputRef = useMask({ mask: '+966 __ ___ ____', replacement: { _: /\d/ } });
	
	const button = useRef(0);
	const [phone, setPhone] = useState(number);
	const [valid, setValid] = useState(true);
	const {request} = useHttp();
	let clazz;
	clazz = valid ? 'input' : 'input input--error';

	useEffect(() => {
		button.current.setAttribute( "disabled", true );
		// eslint-disable-next-line
	}, [])

	const onCheckDisable = (e) => {
		if(e.target.value.length > 15) {
			setValid(true);
			button.current.removeAttribute( "disabled");
		} else {
			setValid(false);
			button.current.setAttribute( "disabled", true );
		}
	}
	const onSendNumber = (phone) => {
		
		request(undefined, {
			command: "phone.set_number",
			id: id,
			phone: phone
			
		}).then(res => {
			setSended(true)
		})  
	}
	return (
		<>
			<li className='card__item'>
				<h3 className="card__title">{title}</h3>
				<form>
					{
						sended ? (
							<>
								<img className="card__taco" src={happyTaco} alt="happy taco"/>
								<button 
									className="button" 
									type="button"
									onClick={() => {setSended(false); getPhoneNumbers()}}
									>Ok</button>
							</>
						) : (
							<>
								<label className="label">
									Phone number
									<input 
									ref={inputRef}
									type="text" 
									name="login" 
									className={clazz}
									placeholder={number}
									defaultValue={number}
									value={phone}
									onChange={(e) => {setPhone(e.target.value); onCheckDisable(e)}}
									></input>
								</label>
								<button 
									ref={button}
									className="button" 
									type="button"
									onClick={() => onSendNumber(phone)}
									>Save</button>
							</>
						)
					}
				</form>
			</li>
		</>
	)
}


export default Catering