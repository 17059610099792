import Spinner from '../components/Spinner/Spinner';
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
// import Skeleton from "../components/skeleton/Skeleton";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
const SetContent = ({process, component}) => {
  switch(process) {
    case 'waiting':
      // return  <Skeleton/>;
      return <Spinner active/>;
    case 'loading':
      return <Spinner active/>;
    case 'confirmed':
      return <ErrorBoundary>{component}</ErrorBoundary>;
    case 'error':
      return <ErrorMessage/>;
    default:
      throw new Error('Unexpected process state');
  }
}

export default SetContent
