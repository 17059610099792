import React,  {useState, useEffect, useRef} from 'react';

import PanelHeader from "../../components/panelHeader/PanelHeader";
import AdminForm from '../../components/AdminForm/AdminForm';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import Spinner from '../../components/Spinner/Spinner';
import { useHttp } from '../../hooks/http.hook';

import EventEmitter from "react-native-md5";
import md5 from "react-native-md5";

const CreateAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  
  
  const [admin, setAdmin] = useState({
    command: 'admin.create',
    name: '',
    login: '',
    password: '',
    repeatPass: '',
    role: '0',
    email: '',
    comment: ''
  })

  const {request, process, setProcess, test} = useHttp();

const form = useRef(0);


const onCreateAdmin = () => {

  ///ВАЖНО. добавить проверки всех полей на введенные данные и выводить ошибку в случае некорреткного ввода или пустого поля
  if (test) {
    setShowAlert(true)
    setTextAlert('The new administrator has been successfully created')
  } else {
    setLoading(true)
    request(undefined, {
          command: 'admin.create',
          name: admin.name,
          login: admin.login,
          password: md5.hex_md5(admin.password),
          role: admin.role,
          email: admin.email,
          comment: admin.comment
        })
        .then(res => {
          console.log(res);
          if (res.status === '1'){
            setTextAlert('The administrator has been successfully created');
          } else {
            setTextAlert('Something went wrong');
          }
        setLoading(false)
        setShowAlert(true)
        })
  }
}


let spinner = loading ? <Spinner active/> : null;
  return (
    <>
      <PanelHeader title="Create an admin"/>
      {spinner}
      <AdminForm admin={admin} setAdmin={setAdmin} buttonTitle="Create an admin" sendData={onCreateAdmin} form={form}></AdminForm>
     
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert}/>
    </>
  )
}

export default CreateAdmin;