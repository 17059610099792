import React, {useState, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
// import useTummiesService from "../../services/TummiesService";
import title from './../../resourses/icons/tummies.png';
import yammy from './../../resourses/icons/yammy.png';
import showPass from './../../resourses/icons/show-pass.png';
import secret from './../../resourses/icons/secret.png';
import  './_login.scss';



const Login = ({login, loginError}) =>  {

    // const {login,loginError } = useTummiesService();

    const [logName, setLogName] = useState('');
    const [pass, setPass] = useState('');
    const [show, setShow] = useState(false);
    const [typeInput, setTypeInput] = useState("password");
    let clazz;
    loginError ? clazz = "input input--error" : clazz = "input";

    const button = useRef(0);

    useEffect(() => {
        button.current.setAttribute( "disabled", true );
    }, [])

    const onLogNameChange = (e) => {
        setLogName(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPass(e.target.value);
    }
    
    const onToggleVisiblePassword = () => {
        setShow(state => !state.show);
        if( typeInput === 'password') {
            setTypeInput('text')
        } else {
            setTypeInput("password")
        }
    }

    const onCheckDisable = (e, stateAnoherInput) => {
        if(e.target.value && stateAnoherInput) {
            button.current.removeAttribute( "disabled" );
        } else {
            button.current.setAttribute( "disabled", true );
        }
    }
    
    return (      
        <div className="login__container">
            <div className="login__wrapper">
                <div className="title">
                    <img src={title} alt="tummies"/>
                </div>
                <div className="login__yammy">
                    <img src={yammy} alt="yammy"/>
                </div>
            </div>
            
            <div className="login__form">
                <label className="label">
                    Login
                    <input 
                    type="text" 
                    name="login" 
                    className={clazz}
                    value={logName}
                    onChange={(e) => {onLogNameChange(e)}}
                    onInput={(e) => onCheckDisable(e, pass)}></input>
                </label>
                <label className="label">
                    Password
                    <input 
                        type={`${typeInput}`}
                        name="password" 
                        className={clazz}
                        value={pass}
                        onChange={(e) => {onPasswordChange(e)}}
                        onInput={(e) => onCheckDisable(e, logName)}></input>
                        <img onClick={() => onToggleVisiblePassword()} src={show ? showPass : secret}/>
                </label>
                    <Link to="/recovery/" className="login__fogot">Fogot your password?</Link>
                <button 
                    ref={button}
                    className="button" 
                    type="button"
                    onClick={() => login(logName, pass)}
                    >Enter</button>
            </div>
        </div>
    )
}

export default Login;