import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";
import { setSelectDay } from "../../redux/slices/workdaysSlice";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";

import "./_orderList.scss";
import attention from "./../../resourses/icons/attention.svg"

const OrderList = () => {

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(null); // !!get this value from server side

  const {request, process, setProcess} = useHttp();

  const workdays = useSelector((state) => state.workdays.nextFiveDays);

  useEffect( () => {
    getOrderList(workdays[activeTab].weekday, currentPage);
    // eslint-disable-next-line
  }, []);

   // get order list
  const getOrderList = (date, pageNumber) => {
      request(undefined, {
        "command": "kitchen.order_total_list",
        "page": `${pageNumber}`,
        "date": `${date}`,
        "search": ""
      }).then((res) => {
        setOrderList(res.message[0].order_list); 
        setTotalPages(res.message[0].max_page);
        setCurrentPage(res.message[0].current_page);
        setProcess('confirmed');
      });
  }
  
  const changePage = (step) => {
    // step  -1 means previous page and +1 is next
    getOrderList(workdays[activeTab].weekday, currentPage + step)
  }

  const changeDate = (i) => {
    dispatch(setSelectDay(workdays[i]))
    setActiveTab(i);
    getOrderList(workdays[i].weekday, 1);
  };

   // поиск
   const onSearch = (e) => {
    setSearch(e.target.value)
    request(undefined, {
      "command": "kitchen.order_total_list", 
      "page": '1',
      "date": `${workdays[activeTab].weekday}`,
      "search": e.target.value
    }).then((res) => {
        setOrderList(res.message[0].order_list); 
        setTotalPages(res.message[0].max_page);
        setCurrentPage(1)
        setProcess('confirmed');
    });
  }

  //render table rows
  const renderItems = (arr) => {
    const orderList = arr.map((item, i) => {
      const {id, name, containAllergen, transaction, added} = item;
      return (
       <li key={id}>
        <Link to={`/order/${id}`} className="list__item orders__item">
          <div className="list__id">{id}</div>
          <div className="list__col-2">{name}</div>
          <div className={`orders__attention ${containAllergen ? "show" : ""}`}
            data-tooltip="Contains an allergen">
            <img src={attention} alt="" />
            </div>
          <div className="list__col-3">{transaction}</div>
          <div className={`status ${added === '1' ? 'ok' : ''}`}></div>
        </Link>
       </li>
      )
    })
    return (
      <ul className="orders list">
        {orderList}
      </ul>
    )
  }

  const tabs = workdays.map((item, i) => {
    return (
      <button key={i} className={`tabs__btn ${activeTab === i ? "active" : ""}`} onClick={() => changeDate(i)}>
        {item.date}
      </button>
    );
  });


  return (
    <>
      <PanelHeader title="List of orders">
        <div className="search">
          <input type="text" placeholder="Search" value={search} onChange={(e) => onSearch(e)}/>
        </div>
      </PanelHeader>

      <div className="orders__tabs tabs">
        {tabs}
      </div>
      
      <SetContent process={process} component={renderItems(orderList)}/>
      
      <div className="order__bottom">
        <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
      </div>
      
    </>
  )

}

export default OrderList;