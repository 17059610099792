import React,  {useState} from 'react';
import generatePass from '../../services/GeneratePass';
import './_adminForm.scss'
import unsecret from '../../resourses/icons/show-pass.png';
import secret from '../../resourses/icons/secret.png';



const AdminForm = ({admin, setAdmin, buttonTitle, sendData, form}) => {
  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState({
    password: false,
    repeatPass: false
  });
  const validateValues = (inputName, inputValue) => {
  let error = {};

    switch(inputName) {
      case 'name':
        if (inputValue.length < 2) {
          error.name = "Name is too short";
        } else {
          error.name = '';
        }
        break;
      case 'login':
        if (inputValue.length < 4) {
          error.login = "Login is too short";
        } else {
          error.login = ""
        }
        break
      case 'email':
        let emailRegExp = /^[a-zA-Z0-9._]+@[a-z.]+\.[a-z]{2,6}$/i;
        if (!emailRegExp.test(inputValue)) {
          error.email = "Email format is incorrect"
        } else {
          error.email = "";
        }
        break;
      case 'password':
        if (inputValue.length < 7) {
          error.password = "Password is too short";
        } else {
          error.password = "";
        }
        break;
      case 'repeatPass':
        if (inputValue !== admin.password) {
          error.repeatPassword = "Passwords do not match";
        } else {
          error.repeatPassword = "";
        }
        break;
      default:
          console.error('Unknown value of input name');
    }
 
    return error;
  };

  const checkForm = () => {
    if (admin.name && admin.login && admin.email && admin.password && admin.repeatPass) {
      for (let key in errors) {
        if (errors[key] !== '') {
          return true
        }
      }
      return false
    }
    return true
  }
 

  const handleChange = (e) => {
    setAdmin(state => ({...state, [e.target.name]: e.target.value}))
    setErrors(state => ({...state, ...validateValues(e.target.name, e.target.value)}))
  }

  const clearForm = () => {
    setAdmin(state => {
      let newState = {...state}
      for (let key in newState) {
        newState[key] = '';
      }
      newState["command"] = 'admin.create';
      return newState;
    })
  }



  return (
    <form className="create-admin" ref={form}>
        <div className="create-admin__box">
          <label className="create-admin__label">
            <span>Name</span>
            <input className={`input ${errors.name ? 'input--error' : ''}`} type="text" name="name"
            value={admin.name} 
            onChange={handleChange}/>
            <div className='error'>{errors.name}</div>
          </label>
          <label className="create-admin__label">
            <span>Login</span>
            <input className={`input ${errors.login ? 'input--error' : ''}`} type="text" name="login"
              value={admin.login}
              onChange={handleChange}/>
              <div className='error'>{errors.login}</div>
          </label>
          <label className="create-admin__label">
            <span>Password</span>
            <input className={`input ${errors.password ? 'input--error' : ''}`} name="password"
              type={showPass.password ? 'text' : 'password'}
              value={admin.password}
              autoComplete='off'
              onChange={handleChange}/>
              <div className='error'>{errors.password}</div>
            <img
              onClick={() => setShowPass(state => ({...state, password: !state.password}))}
              src={showPass.password ? unsecret : secret}
              alt="show pass"/>
            <button className="create-admin__gen" type="button"
              onClick={() => {
                setAdmin(state => ({...state, password: generatePass()}))
                setErrors(state => ({...state, password: ''}))
              }}>
                Generate a strong password
              </button>
          </label>
          <label className="create-admin__label">
            <span>Repeat the password</span>
            <input className={`input ${errors.repeatPassword ? 'input--error' : ''}`} name="repeatPass"
              type={showPass.repeatPass ? 'text' : 'password'}
              value={admin.repeatPass}
              autoComplete='off'
              onChange={handleChange}/>
              <div className='error'>{errors.repeatPassword}</div>
            <img
              onClick={() => setShowPass(state => ({...state, repeatPass: !state.repeatPass }))}
              src={showPass.repeatPass ? unsecret : secret}
              alt="show pass"/>
          </label>
        </div>

        <div className="create-admin__box">
          <label className="create-admin__label">
            <span>User role</span>
            <div className="create-admin__checkboxes">
            <label className="create-admin__checkbox">

              <input className="sr-only" type="radio" name="role" value="0"
              checked={admin.role === '0' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            <label className="create-admin__checkbox">
              <input className="sr-only" type="radio" name="role" value="1"
              checked={admin.role === '1' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            <label className="create-admin__checkbox">
              <input className="sr-only" type="radio" name="role" value="2" 
              checked={admin.role === '2' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            <label className="create-admin__checkbox">
              <input className="sr-only" type="radio" name="role" value="3"
              checked={admin.role === '3' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            <label className="create-admin__checkbox">
              <input className="sr-only" type="radio" name="role" value="4" 
              checked={admin.role === '4' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            <label className="create-admin__checkbox">
              <input className="sr-only" type="radio" name="role" value="5" 
              checked={admin.role === '5' ? true : false}
              onChange={handleChange}/>
              <span>User role </span>
            </label>
            </div>
          </label>
          <label className="create-admin__label">
            <span>E-mail</span>
            <input className={`input ${errors.email ? 'input--error' : ''}`} type="text" name="email"
            value={admin.email}
            onChange={handleChange}/>
            <div className='error'>{errors.email}</div>
          </label>
          <label className="create-admin__label">
            <span>Comment</span>
            <input className="input" type="text" name="comment"
            value={admin.comment}
            onChange={handleChange}/>
          </label>
        </div>
        <div className="create-admin__btns">
          <button type="button" className="create-admin__btn button button--orange"
          onClick={clearForm}>Clear the form</button>
          <button type="button" className="create-admin__btn button" disabled={checkForm()}
          onClick={() => {
            sendData();
            clearForm();
          }}>{buttonTitle}</button>
        </div>
    </form>
  )
}

export default AdminForm;
