import { configureStore } from "@reduxjs/toolkit";
import authReduser from "./slices/authSlice";
import adminReduser from "./slices/adminSlice";
import matrixReduser from "./slices/matrixSlice";
import workdaysReduser from "./slices/workdaysSlice";


export const store = configureStore({
    reducer: {
      auth: authReduser,
      admin: adminReduser,
      matrix: matrixReduser,
      workdays: workdaysReduser
    },
})