const getDecryptedStatus = (statusCode) => {
  switch (statusCode) {
    case '00': 
      return 'Invalid Request'
    case '0': 
      return 'Invalid Request'
    case '11': 
      return 'Check status Failed'
    case '13': 
      return 'Purchase Failure'
    case '14':
      return 'Purchase Success'
    case '19':
      return 'Transaction pending'
    case '20':
      return 'On hold'
    default: 
      return `Unexpected status ${statusCode}`
  }
}

export default getDecryptedStatus