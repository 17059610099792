import React, { useState, useEffect } from "react";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";
import './_youtube.scss'
import trash from "./../../resourses/icons/trash.png";
const Youtube = () => {

    const [createCode, setCreateCode] = useState(false)
    const [videos, setVideos] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
        link: null
    });

    const { request, process, setProcess, test } = useHttp();


    // ///получаем список видео
    const getVideos = () => {
        if (test) {
            setVideos([
                { id: 1, name: 'Name video 1 ', link: 'https://www.Tummies work' },
                { id: 2, name: 'Name video 2 ', link: 'https://www.Tummies work' },
                { id: 3, name: 'Name video 3 ', link: 'https://www.Tummies work' }
            ])
        } else {
            request(undefined, {
                "command": "youtube.get_you_tube_links"
            })
                .then(res => {
                    setProcess('confirmed');
                    setVideos(res.message)
                })
        }
    }

    useEffect(() => {
        getVideos();
    }, [])

    //обработчик инпутов
    const handlerInput = (id, target) => {
        const { name, value } = target
        setVideos(state => {
            const newState = structuredClone(state);
            return newState.map(item => {
                if (item.id === id) {
                    return { ...item, [name]: value, changed: true };
                  }
                  return item;
            })
        })
    }

    // отправляем запрос на сохранине видео по id
    const onSave = (id) => {
        const savedVideo = videos.find(item => item.id === id) // находим сохраняемое видео
            request(undefined, {
                "command": "youtube.set_you_tube_links",
                "id": id,
                "name": savedVideo.name,
                "link": savedVideo.link
            })
                .then(res => {
                    console.log(res);
                    setProcess('confirmed');
                    setCreateCode(false);
                    setVideos(res.message);
                })
    }

    // добавляем новое видео
    const addVideo = () => {
        setCreateCode(true);
        setVideos(state => {
            const newState = structuredClone(state);
            return [...newState, {id: '', name: '', link: ''}]
        })
    }

    // отправляем запрос на удаление видео
    const removeVideo = (id) => {
        if(id === "") {
            setShowConfirm(false);
            setCreateCode(false);
            setVideos(state => {
                const newState = structuredClone(state);
                return newState.filter(item => item.id !== "")
            })
            return
        }
        request(undefined, {
            "command": "youtube.delete_you_tube_links",
            "id": id
        })
            .then(res => {
                setProcess('confirmed');
                setShowConfirm(false);
                setVideos(res.message)
            })
    }

    const onConfirmDelete = (id, name, link) => {
        setShowConfirm(true);
        setTargetConfirm({id, name, link})
    }

   

    const videoList = videos.map(item => {
        const {id, name, link, changed} = item;
        const disableBtn = name && link && changed ? false : true;
        return (
            <li
                className="youtube__item"
                key={id}>
                <div
                    className="youtube__box">
                    <input
                        className="youtube__input" type="text" name="name" placeholder="name video"
                        value={name}
                        onChange={(e) => handlerInput(id, e.target)} />
                    <input
                        className="youtube__input"
                        type="text"
                        name="link"
                        placeholder="link"
                        value={link}
                        onChange={(e) => handlerInput(id, e.target)}/>
                    <button
                        disabled={disableBtn}
                        className="youtube__save button"
                        onClick={() => onSave(id)}>
                            Save
                    </button>
                </div>
                <button
                    className="youtube__del btn-trash"
                    onClick={() => onConfirmDelete(id, name, link)}>
                    <img src={trash} alt="delete" />
                </button>
            </li>
            )
        })
    
    const AddBtn = () => (
        <button className="youtube__add button" onClick={addVideo}>
            Add video +
        </button>
    )
    return (
        <>
            <PanelHeader title="Youtube" />
            <ul className="youtube">
                <SetContent process={process} component={videoList} />
            </ul>
            {createCode ? null : <AddBtn />}
            <ConfirmModal question='Delete the video?' text1={targetConfirm.name} text2={targetConfirm.link} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeVideo(targetConfirm.id)}/>
        </>
    )
}

export default Youtube;