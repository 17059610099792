import React, {useEffect, useState} from "react";
import PanelHeader from "../../components/panelHeader/PanelHeader";
import Pagination from "../../components/Pagination/Pagination";

import { useHttp } from "../../hooks/http.hook";
import SetContent from "../../utils/SetContent";
import getTypeSubscription from "../../utils/getTypeSubscription";
import getDecryptedStatus from "../../utils/getDecryptedStatus";

import "./_transaction.scss";
const Transaction = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null) // !!get this value from server side
  const [transactionList, setTransactionList] = useState([]);

  const {request, process, setProcess} = useHttp();


  useEffect(() => {
    getTransactionsList(currentPage);
    // eslint-disable-next-line
  }, []);

  const changePage = (step) => {
    getTransactionsList(currentPage + step);
    setCurrentPage(currentPage + step)
  }

  const getTransactionsList = (currentPage) => {
    request(undefined, {
      "command": "payment.read_order_total", // команда для получения списка транзакций от 20 шт
      "page": `${currentPage}`
    }).then((res)=>{
      console.log(res);
      setTotalPages(res.message[0].max_page)
      setTransactionList(res.message[0].transaction_list); //  Set the initial list of transactions to be displayed on
      setCurrentPage(currentPage);
      setProcess('confirmed');
    });
  }

  //render table rows
  const renderItems = (arr) => {
    console.log('arr', arr);
    const tableRows = arr.map((item, i) => {
      const {orderId, userId, date, status, amount, plan, code, percent} = item;
      return (
        <tr>
          <td>{(i + 1) + (currentPage -1) * 20}</td>
          <td>{orderId}</td>
          <td>{userId}</td>
          <td>{date}</td>
          <td>{getDecryptedStatus(status)}</td>
          <td>{amount} SAR</td>
          <td>{getTypeSubscription(plan)} </td>
          <td>{code === '0' ? null : code}</td>
          <td>{percent}</td>
        </tr>
      )
    })
    return (
      <table className="table transactions">
      <tr>
        <th></th>
        <th>ID transaction</th>
        <th>ID the user</th>
        <th>Time</th>
        <th>Status</th>
        <th>The amount</th>
        <th>Selected tariff</th>
        <th>Promo code</th>
        <th>Discount</th>
      </tr>
      {tableRows}
      </table>
    )
  }

  return (
    <>
      <PanelHeader title="Transaction"/>
      
      <SetContent process={process} component={renderItems(transactionList)}/>
      
      <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />
    </>
  )
}

export default Transaction;