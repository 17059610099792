import React from "react";
import { Route, Routes } from "react-router-dom";
import { NavLink, useNavigate } from 'react-router-dom';
import Catering from '../Catering/Catering'
import AdminPanel from "../AdminPanel/AminPanel";
import CreateAdmin from '../CreateAdmin/CreateAdmin'
import AdminList from "../AdminsList/AdminsList";
import Transaction from "../Transaction/Transaction";
import DishList from "../DishList/DishList";
import EditDish from "../EditDish/EditDish";
import CreateDish from "../CreateDish/CreateDish";
import MainSlider from "../MainSlider/MainSlider";
import Rate from "../Rate/Rate";
import Youtube from "../Youtube/Youtube";
import Children from "../Children/Children";
import EditChild from "../EditChild/EditChild";
import Parents from "../Parents/Parents";
import EditParent from "../EditParent/EditParent";
import OrderList from "../OrderList/OrderList";
import Order from "../Order/Order";
import Kitchen from "../Kitchen/Kitchen";
import Promocode from "../Promocode/Promocode";
import Settings from "../Settings/Settings";
import Support from "../Support/Support";

import Editor from "../Editor/Editor";
import './_main.scss'

import yammy from './../../resourses/icons/yammy.png';




const Main = () =>  {
    const navigate = useNavigate();
    const {name, login} = JSON.parse(window.sessionStorage.getItem('admin'))

    const logOut = () => {
        window.sessionStorage.clear();
        navigate('/');
        window.location.reload();
    }
   return (
        <div className="main">
            <div className="navigation">
                <div className="navigation__top">
                    <img className="navigation__logo" src={yammy} alt="logo" />
                    <div className="navigation__user">
                        <div className="navigation__name">{name}</div>
                        <div className="navigation__login">{login}</div>
                    </div>
                </div>
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/admins-list">
                            List of admins
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/transaction">
                            Transaction
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/dish-list">
                            Meals
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/rate">
                            Prices
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/main-slider">
                            Main Slider
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/youtube">
                            Entertainment
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/catering">
                            Catering
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/children">
                            Children
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/parents">
                            Parents
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/order-list">
                            List of orders
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/kitchen">
                            Kitchen
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/promocode">
                            Promo codes
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/settings">
                            Settings
                        </NavLink>
                    </li>
                    <li className="navigation__item">
                        <NavLink className={({ isActive }) => (`navigation__link ${isActive ? 'active' : ''}`)} to="/support">
                            Support
                        </NavLink>
                    </li>
                </ul>
                <div className="navigation__bottom">
                    <NavLink to="/" className="navigation__exit">
                        <button className="navigation__logout" onClick={logOut}>
                            Exit
                        </button>
                    </NavLink>
                </div>
            </div>
            <div className="content">
                <Routes>
                    <Route path="/" element={<AdminPanel/>}/>
                    <Route path="/admins-list" element={<AdminList />}/>
                    <Route path="/catering" element={<Catering />}/>
                    <Route path="/create-admin" element={<CreateAdmin />}/>
                    <Route path="/edit-admin" element={<Editor />}/>
                    <Route path="/transaction" element={<Transaction />}/>
                    <Route path="/dish-list" element={<DishList />}/>
                    <Route path="/edit-dish/:id" element={<EditDish />}/>
                    <Route path="/new-dish/" element={<CreateDish />}/>
                    <Route path="/main-slider/" element={<MainSlider />}/>
                    <Route path="/rate/" element={<Rate />}/>
                    <Route path="/youtube/" element={<Youtube />}/>
                    <Route path="/children/" element={<Children />}/>
                    <Route path="/edit-child/:id" element={<EditChild />}/>
                    <Route path="/parents/" element={<Parents />}/>
                    <Route path="/edit-parent/:id" element={<EditParent />}/>
                    <Route path="/order-list/" element={<OrderList />}/>
                    <Route path="/order/:id" element={<Order />}/>
                    <Route path="/kitchen/" element={<Kitchen />}/>
                    <Route path="/promocode/" element={<Promocode />}/>
                    <Route path="/settings/" element={<Settings />}/>
                    <Route path="/support/" element={<Support />}/>
                </Routes>
            </div>
        </div>
   )
}

export default Main;