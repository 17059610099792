import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import Pagination from "../../components/Pagination/Pagination";
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import SetContent from "../../utils/SetContent";
import './_parents.scss'
import trash from "./../../resourses/icons/trash.png";
const Parents = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null);
    const [parents, setParents] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [targetConfirm, setTargetConfirm] = useState({
        id: null,
        name: null,
        email: null
    });

    const { request, process, setProcess, test } = useHttp();



    // получаем список родителей
    const getParents = (pageNumber) => {
        if (test) {
            setParents([
                { id: 1, name: 'Name', email: 'email' },
                { id: 2, name: 'Name', email: 'email' },
                { id: 3, name: 'Name', email: 'email' }
            ])
            setProcess('confirmed');
        } else {
            request(undefined, {
                "command": "users.get_all_parent_list",
                "page": `${pageNumber}`
            })
                .then(res => {
                    setProcess('confirmed');
                    setParents(res.message[0].parents);
                    setCurrentPage(res.message[0].current_page);
                    setTotalPages(res.message[0].max_page);
                })
        }
    }

    useEffect(() => {
        getParents(currentPage);
        // eslint-disable-next-line
    }, [])



    const changePage = (step) => {
        // step  -1 means previous page and +1 is next
        getParents(+currentPage + step);
        setCurrentPage(state => +state + step);
      }

    // отправляем запрос на удаление родителя
    const removeParent = (id) => {
        setShowConfirm(false);

        request(undefined, {
            "command": "users.remove_parent",
            "id": `${id}`
        }).then(res => {
                if (res.status === '1') {
                    setShowAlert(true);
                    setTextAlert('The parent has been successfully deleted');
                    getParents(currentPage);
                    setProcess('confirmed');
                } else {
                    setProcess('error');
                }
            })
    }

    const onConfirmDelete = (id, name, email) => {
        setShowConfirm(true);
        setTargetConfirm({id, name, email})
    }

    const renderItems = (arr) => {
        const parentsList = arr.map(item => {
            const {id, name, email} = item;
            return (
                <li key={id} className="rows-list__item">
                <div className='rows-list__box parents__box'>
                    <span>{id}</span>
                    <span>{name}</span>
                    <span>{email}</span>
                   <Link className="rows-list__btn button" to={`/edit-parent/${id}`}>Edit</Link>
                </div>
                <button 
                    className='btn-trash'
                    onClick={() => onConfirmDelete(id, name, email)}
                    >
                    <img src={trash} alt="delete"/>
                </button>
            </li>
            )
        })

        return (
            <ul className="rows-list">
                {parentsList}
            </ul>
        )
    }
   
    return (
        <>
            <PanelHeader title="Parents" />

            <SetContent process={process} component={renderItems(parents)}/>

            <Pagination currentPage={currentPage} totalPages={totalPages} changePage={changePage} />

            <ConfirmModal question='Delete a parent?' text1={targetConfirm.name} text2={targetConfirm.email} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeParent(targetConfirm.id)}/>
            <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
        </>
    )
}

export default Parents;