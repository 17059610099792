const getTypeSubscription = (numPackagePlan) => {
  switch (numPackagePlan) {
    case '2':
      return 'weekly/mounthly'
    case '1':
      return 'weekly'
    case '0':
      return 'mounthly'
    default:
      return ''
  }
}

export default getTypeSubscription;