import React from 'react';

import './_btnPrint.scss'
const BtnPrint = () => {

    return (
      <div className="print">Print
        <button className="square-btn square-btn--print" onClick={() => window.print()}></button>
      </div>
    )
}

export default BtnPrint;